<template>
  <div class="careers" v-if="carrersSingleton.show_section">
    <v-container fluid>
      <v-row align="center" style="background-color: #f7f4eb">
        <v-col cols="12" sm="6" class="pa-0">
          <v-img
            :src="
              carrersSingleton.cover !== null
                ? 'https://www.lakewaycms.actstudio.xyz' +
                  carrersSingleton.cover.path
                : ''
            "
            class="img-careers"
            eager
          ></v-img>
        </v-col>
        <v-col cols="12" sm="6" class="text-center">
          <div class="d-block mx-auto texts-intro">
            <span class="d-block semibold title-intro">{{
              carrersSingleton.card_intro.title
            }}</span>
            <span>{{ carrersSingleton.card_intro.text }}</span>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container :fluid="$vuetify.breakpoint.lgAndDown || $vuetify.breakpoint.width > 2299" class="content-jobs">
      <v-row
        v-for="(job, j) in jobs"
        v-bind:key="j"
        align="center"
        style="background-color: #f7f4eb"
        class="item-complete-job"
      >
        <v-col class="px-6 item-job">
          <v-row no-gutters>
            <v-col>
              <span class="inter subtitles-a semibold">
                {{ job.name }}
              </span>
            </v-col>
          </v-row>
          <v-row no-gutters class="card-location">
            <v-col cols="auto">
              <span class="semibold">Location:</span> {{ job.city }}
            </v-col>
            <v-col cols="auto" class="pl-sm-5 mt-1 mt-sm-0">
              <span class="semibold">Department:</span> {{ job.department }}
            </v-col>
            <v-col cols="auto" class="pl-md-5 mt-1 mt-md-0">
              <span class="semibold">Type:</span> {{ job.type }}
            </v-col>
            <v-col cols="12" class="mt-5">
              <p v-html="job.description" class="job-description mb-0"></p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" class="px-6">
          <v-btn
            rounded
            color="#008137"
            dark
            elevation="0"
            class="btn-general white--text"
            :ripple="false"
            :href="'mailto:' + carrersSingleton.email_apply_now"
            >Apply Now</v-btn
          >
        </v-col>
      </v-row>
      <!-- <template v-for="(state, s) in states">
                <v-row :key="'state-'+s">
                    <v-col>
                        <v-row>
                            <v-col>
                                <span class="markazi" style="font-size: 60px;">
                                    {{state}}
                                </span>
                            </v-col>
                        </v-row>
                        <template v-for="(job,j) in jobs">
                            <template v-if="job.state == state">
                                <v-row :key="'job-'+s+'-'+j" align="center" style="background-color: #F7F4EB;" class="my-6">
                                    <v-col class="px-6">
                                        <v-row>
                                            <v-col>
                                                <span class="inter" style="font-size:35px;">
                                                    {{job.name}}
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="auto">
                                                {{job.city}}
                                            </v-col>
                                            <v-col cols="auto">
                                                {{job.department}}
                                            </v-col>
                                            <v-col cols="auto">
                                                {{job.city}}
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="auto" class="px-6">
                                        <v-btn rounded color="#008137" dark>Apply Now</v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                        </template>
                    </v-col>
                </v-row>
            </template> -->
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Careers",
  data() {
    return {
      jobs: [],
      carrersSingleton: {
        cover: {
          path: "",
        },
        card_intro: {
          title: "",
          text: "",
        },
      },
    };
  },
  computed: {
    states: function () {
      let n = [];

      for (let index = 0; index < this.jobs.length; index++) {
        const element = this.jobs[index];
        if (!n.includes(element.state)) {
          n.push(element.state);
        }
      }

      return n;
    },
  },
  async beforeCreate() {
    let key = "";

    const opts =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijlkmnopqrstuvwxyz1234567890";

    for (let index = 0; index < 10; index++) {
      key = key + opts.charAt(Math.random() * 61);
    }
    this.carrersSingleton = await fetch(
      "https://www.lakewaycms.actstudio.xyz/api/singletons/get/carrers?q=" +
        key,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          populate: 1,
        }),
      }
    )
      .then((data) => data.json())
      .then((data) => (this.carrersSingleton = data.content));
  },
  beforeMount: function () {
    //https://www.lakewaycms.actstudio.xyz/collections/entries/Careers
    fetch("https://www.lakewaycms.actstudio.xyz/api/collections/get/Careers", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        filter: {},
      }),
    })
      .then((res) => res.json())
      .then((res) => (this.jobs = res.entries));
  },
};
</script>

<style lang="scss" scoped>
$xxl: 2300px;
$xl: 1903px;
$lg: 1263px;
$md: 959px;
$sm: 599px;

.img-careers {
  height: 700px;
  @media screen and(max-width: $xxl) {
    height: 463px;
  }

  @media screen and(max-width: $xl) {
    height: 380px;
  }

  @media screen and(max-width: $sm) {
    height: 290px;
  }
}

.title-intro {
  display: block;
  font-size: 60px;
  margin-bottom: 36px;

  @media screen and(max-width: $xxl) {
    font-size: 45px;
    margin-bottom: 15px;
  }

  @media screen and(max-width: $xl) {
    font-size: 36px;
  }

  @media screen and(max-width: $sm) {
    font-size: 32px;
  }
}

.card-location {
  @media screen and(min-width: $xxl) {
    margin-top: 30px;
  }
  @media screen and(max-width: $xxl) {
    margin-top: 15px;
  }
}

.item-job {
  padding-top: 70px;
  padding-bottom: 70px;

  @media screen and(max-width: $xl) {
    padding-top: 26px;
    padding-bottom: 26px;
  }
  @media screen and(max-width: $sm) {
    padding-bottom: 13px;
  }
}

.item-complete-job {
  @media screen and(min-width: $xxl) {
    padding-left: 70px;
    padding-right: 70px;
  }
  @media screen and(max-width: $xxl) {
    padding-left: 30px;
  }
  @media screen and(max-width: $sm) {
    padding-left: 0px;
    padding-bottom: 20px;
  }
  &:first-child {
    margin-top: 51px;
  }
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 98px;
  }
}

.texts-intro {
  max-width: 700px;

  @media screen and(max-width: $xxl) {
    max-width: 500px;
  }
  @media screen and(max-width: $xl) {
    max-width: 372px;
  }
  @media screen and(max-width: $sm) {
    padding: 50px 0;
  }
}

.content-jobs {
  @media screen and(min-width: $xxl) {
    padding: 0 150px;
    margin-top: 150px;
  }
  @media screen and(max-width: $xl) {
    padding: 0 62px;
  }

  @media screen and(max-width: $sm) {
    padding: 0 46px;
  }
}

.job-description {
  @media screen and(min-width: $xxl) {
    max-width: 1541px;
    margin-top: 10px;
  }

  @media screen and(max-width: $xxl) {
    max-width: 1188px;
  }
}
</style>